import { AdvancedNavigatorUAData } from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"

enum OperativeSystem {
  WINDOWS = "Windows",
  MACOS = "macOS",
}

type DownloadLinkProps = Record<OperativeSystem, Record<string, string>>

type LandingDownloadOptionsProps = {
  userAgentData: AdvancedNavigatorUAData | undefined
  links: Record<string, Record<string, string>>
  hideLogo?: boolean
  title?: string
  redirectPath?: string
}

type DownloadOptionsProps = {
  text: string
  image: string
  link?: string
  redirectOs?: string
  arch?: string
  archInfo?: string
}

export type {
  DownloadOptionsProps,
  LandingDownloadOptionsProps,
  DownloadLinkProps,
}
export { OperativeSystem }
